import { createSlice } from "@reduxjs/toolkit";

import {
  addNewRackThunk,
  addRacksToDesignThunk,
  assignedEmployeeThunk,
  assignPackagerThunk,
  createNewDesignThunk,
  createNewInventoryThunk,
  createNewOrderThunk,
  createNewUserThunk,
  deleteDesignThunk,
  deleteUserThunk,
  editDesignThunk,
  editUserThunk,
  fetchRTOThunk,
  generateLabelThunk,
  getAllCompletedOrdersThunk,
  getAllCustomeOrdersThunk,
  getAllDesignsNamesThunk,
  getAllDesignsThunk,
  getAllDispatchOrderThunk,
  getAllInventoryThunk,
  getAllPendingOrdersThunk,
  getAllRacksByFloorThunk,
  getAllRacksThunk,
  getAllRemittanceCODOrders,
  getAllReturnOrdersThunk,
  getAllScannedOrdersThunk,
  getAllUsersThunk,
  getDesignsByFloorAndRackThunk,
  getGetOrdersBySkuThunk,
  getInventoryDetailsByProductIdThunk,
  getOrderDeliverdThunk,
  getOrderIdListWithItemsThunk,
  getPendingOrdersByEmployeeThunk,
  getPendingOrdersForPackagingThunk,
  markOrderAsCompletedThunk,
  UserLoginThunk,
} from "./chatBotCreatorThunk";

export const chatBotCreatorSlice = createSlice({
  name: "ChatBotCreatorSlice",
  initialState: {
    organizationName: "PUNEKAR COTTON",
    currentBot: {
      botId: localStorage?.getItem("botId")
        ? localStorage?.getItem("botId")
        : null,
      botName: null,
      actions: [],
    },

    // ---------- user data ----------- //
    currentLocale: "en-US",
    loginToken: "",
    userRole: "",
    username: "",
    isUserLogin: false,
    // ---------- user data ----------- //

    // ---------- loaders ----------- //

    showLoginLoader: false,
    showAddDesignLoader: false,
    showGetAllDesignsLoader: false,
    showSkuDetailsLoader: false,
    showAddInventoryLoader: false,
    showGetAllInventoryLoader: false,
    showAddOrderLoader: false,
    showGetAllPendingOrdersLoader: false,
    showGetAllPendingOrdersByEmployeeLoader: false,
    showGetAllCompletedOrdersLoader: false,
    showGetAllRemittanceOrdersLoader: false,
    showGetAllReturnOrdersLoader: false,
    showAddRackLoader: false,
    showAddRackToDesignLoader: false,
    showRackListByFloorLoader: false,
    showRackListLoader: false,
    showAddUserLoader: false,
    showGetAllUsersLoader: false,
    showGetDesignsByFloorAndRackLoader: false,
    showGetAllOrderIdListWithItemsLoader: false,
    showEditPackedProductQuantityLoader: false,
    showMarkOrderAsCompletedLoader: false,
    showGetPendingOrdersForPackagingLoader: false,
    showGetOrdersBySkuLoader: false,
    showGetOrdersDeliverdLoader: false,
    showGetAllCustomLoader: false,
    showEditDesignLoader: false,
    showDeleteDesignLoader: false,
    showDeleteUserLoader: false,
    showEditUserLoader: false,
    showAssignEmployeeLoader: false,
    showAssignPackagerLoader: false,
    showGetAllDispatchOrderLoader: false,
    showScannedOrderLoader: false,
    fetchingRTO: false,
    fetchRTOStatus: {
      show: false,
      message: "",
      status: "",
    },
    // ---------- loaders ----------- //

    // ---------- error messages ----------- //
    loginErrorMessage: "",
    addDesignErrorMessage: "",
    getAllDesignsErrorMessage: "",
    skuDetailsErrorMessage: "",
    addInventoryErrorMessage: "",
    getAllInventoryErrorMessage: "",
    addOrderErrorMessage: "",
    getAllPendingOrdersErrorMessage: "",
    getAllCompletedOrdersErrorMessage: "",
    getAllRemittanceOrdersErrorMessage: "",
    getAllReturnOrdersErrorMessage: "",
    addRackToDesignErrorMessage: "",
    getRackListErrorMessage: "",
    rackListByFloorErrorMessage: "",
    addUserErrorMessage: "",
    getAllUsersErrorMessage: "",
    getDesignsByFloorAndRackErrorMessage: "",
    getAllOrderIdwithItemsErrorMessage: "",
    editPackedProductQuantityErrorMessage: "",
    markOrderAsCompletedErrorMessage: "",
    getPendingOrdersForPackagingErrorMessage: "",
    getOrdersBySkuErrorMessage: "",
    getOrdersDeliverdErrorMessage: "",
    getCustomeOrderErrorMessage: "",
    editDesignErrorMessage: "",
    deleteDesignErrorMessage: "",
    editUserErrorMessage: "",
    deleteUserErrorMessage: "",
    assignEmployeeErrorMessage: "",
    assignPackagerErrorMessage: "",
    errorAddRackMessage: "",
    getcustomeOrdermessage: "",
    getAllDispatchOrderErrorMessage: "",
    fetchingRTOErrorMessage: "",
    getAllScannedOrdersErrorMessage: "",
    // ---------- error messages ----------- //

    reloadDesigns: 0,
    reloadUsers: 0,

    // ---------- edit modals ----------- //
    showEditDesignModal: false,
    showEditUserModal: false,
    // ---------- edit modals ----------- //

    // ---------- delelte modals ----------- //
    showDeleteDesignModal: false,
    showDeleteUserModal: false,
    // ---------- delelte modals ----------- //

    designList: [],
    totalDesignCount: 0,

    inventoryList: [],
    totalInventoryCount: 0,

    userList: [],
    totalUserCount: 0,

    rackList: [],
    rackListByFloor: [],
    returnOrders: [],
    scannedOrderList: [],
    scannedOrderTotalCount: 0,
    returnOrdersTotalCount: 0,
    orderList: [],
    orderList1: [],
    fetchRTOResult: "",
    totalOrderCount: 0,
    totalOrderCount1: 0,
    pendingOrdersList: [],
    orderIdListWithItems: [],
    designListByFloorAndRack: [],
    skuDetails: null,
    pendingOrderItemsList: [],
    pendingOrderTotal: [],
    orderListBySku: [],

    newInventoryArray: [],
    notFoundSku: [],

    OrderDeliverd: [],

    generatedLabel: "",
    isLabelGenerated: {
      awb: "",
      status: false,
    },

    labelURL: {
      url: "",
      hasURL: false,
    },
    dispatchedOrders: [],
  },

  reducers: {
    onInitialize: (state, action) => {
      state.currentBot = {
        botId: "",
        botName: null,
        actions: [
          {
            isVisibleOnScreen: true,
            editOn: true,
            botActionId: "main_menu",
            botActionName: [
              {
                locale: "en-US",
                text: "Main Menu",
              },
            ],
            botActionIntent: "initialize_intent",
            role: ["admin", "packager", "inventory"],
            showActionPanel: true,
            actionPanelHeader: {
              editOn: true,
              panelHeader: [
                {
                  locale: "en-US",
                  text: "",
                },
              ],
            },
            childActions: [],
            childItems: [],
          },
        ],
        trainingPhrasesCollection: [],
      };
    },

    onSelectedBot: (state, action) => {
      const { currentBotData } = action.payload;
      state.currentBot.botId = currentBotData.botId;
      state.recepientId = currentBotData.recepientId;
      state.currentBot.botName = currentBotData.botName;
      state.currentBot.actions = currentBotData.actions;
      localStorage.setItem("botId", currentBotData.botId);
    },

    updateInventoryArray: (state, action) => {
      state.newInventoryArray.push(action.payload);
    },

    addNewAction: (state, action) => {
      const { parentBotActionId, actionName, actionId, role, icon } =
        action.payload;

      const newChildAction = {
        botActionId: actionId,
        editOn: false,
        isSelected:
          actionName === "Inventory" || actionName === "List Inventory"
            ? true
            : false,
        role,
        icon,
        botActionName: [
          {
            locale: "en-US",
            text: actionName,
          },
        ],
        // isVisibleOnScreen: true,
        actionPanelHeader: {
          editOn: true,
          panelHeader: [
            {
              locale: "en-US",
              text: "",
            },
          ],
        },
      };

      const currentParentAction = state.currentBot.actions.find(
        (botAction) => botAction.botActionId === parentBotActionId
      );
      currentParentAction.childActions.push(newChildAction);
      state.changesDetected = true;
    },

    botActionClose: (state, action) => {
      const { parentBotActionId } = action.payload;
      let parentBotActionIndexInMainArray = state.currentBot.actions.findIndex(
        (botAction) => botAction.botActionId === parentBotActionId
      );

      for (
        let indexOfActions = parentBotActionIndexInMainArray;
        indexOfActions < state.currentBot.actions.length;
        ++indexOfActions
      ) {
        state.currentBot.actions[indexOfActions].isVisibleOnScreen = false;

        for (
          let indexOfChildActions = 0;
          indexOfChildActions <
          state.currentBot.actions[indexOfActions].childActions.length;
          ++indexOfChildActions
        ) {
          state.currentBot.actions[indexOfActions].childActions[
            indexOfChildActions
          ].isSelected = false;
        }
      }
      let lastVisibleBlade = state.currentBot.actions.filter(
        (action) => action.isVisibleOnScreen === true
      );
      lastVisibleBlade[lastVisibleBlade.length - 1]?.childActions?.map(
        (childAction) => {
          childAction.isSelected = false;
        }
      );
      state.changesDetected = true;
    },

    addNewBotActionToBladeActions: (state, action) => {
      state.showLoginLoader = false;
      state.showAddDesignLoader = false;
      state.showGetAllDesignsLoader = false;
      state.showSkuDetailsLoader = false;
      state.showAddInventoryLoader = false;
      state.showGetAllInventoryLoader = false;
      state.showAddOrderLoader = false;
      state.showGetAllReturnOrdersLoader = false;
      state.showGetAllPendingOrdersLoader = false;
      state.showAddRackLoader = false;
      state.showAddRackToDesignLoader = false;
      state.showRackListByFloorLoader = false;
      state.showRackListLoader = false;
      state.showAddUserLoader = false;
      state.showGetAllUsersLoader = false;
      state.showMarkOrderAsCompletedLoader = false;
      state.showGetPendingOrdersForPackagingLoader = false;
      state.showGetOrdersBySkuLoader = false;
      state.showAssignEmployeeLoader = false;
      state.showAssignPackagerLoader = false;
      // ---------- loaders ----------- //

      // ---------- error messages ----------- //
      state.loginErrorMessage = "";
      state.addDesignErrorMessage = "";
      state.getAllDesignsErrorMessage = "";
      state.skuDetailsErrorMessage = "";
      state.addInventoryErrorMessage = "";
      state.getAllInventoryErrorMessage = "";
      state.addOrderErrorMessage = "";
      state.markOrderAsCompletedErrorMessage = "";
      state.getAllPendingOrdersErrorMessage = "";
      state.getAllReturnOrdersErrorMessage = "";
      state.getAllPendingOrdersByEmployeeErrorMessage = "";
      state.addRackToDesignErrorMessage = "";
      state.getRackListErrorMessage = "";
      state.rackListByFloorErrorMessage = "";
      state.addUserErrorMessage = "";
      state.getAllUsersErrorMessage = "";
      state.getPendingOrdersForPackagingErrorMessage = "";
      state.getOrdersBySkuErrorMessage = "";
      state.assignEmployeeErrorMessage = "";
      state.assignPackagerErrorMessage = "";
      // ---------- error messages ----------- //

      // ---------- edit modals ----------- //
      state.showEditDesignModal = false;
      state.showDeleteDesignModal = false;
      state.showEditUserModal = false;
      state.showDeleteUserModal = false;
      //----------------Assign Employee PendingOrders-----------------//
      state.showAssignEmployeeModel = false;
      state.showAssignPackagerModel = false;
      // ---------- edit modals ----------- //

      const {
        childActionId,
        parentBotActionId,
        isLastBlade,
        isInitialize,
        role,
      } = action.payload;
      // Search for the actual Action existence that should be connected to the child action
      const botAction = state.currentBot.actions.find(
        (botAction) => botAction.botActionId === childActionId
      );

      // Search the Parent means the action where action item has been clicked
      const parentBotAction = state.currentBot.actions.find(
        (botAction) => botAction.botActionId === parentBotActionId
      );

      const parentBotActionIndexInMainArray =
        state.currentBot.actions.findIndex(
          (botAction) => botAction.botActionId === parentBotActionId
        );

      for (
        let indexOfActions = parentBotActionIndexInMainArray + 1;
        indexOfActions < state.currentBot.actions.length;
        ++indexOfActions
      ) {
        state.currentBot.actions[indexOfActions].isVisibleOnScreen = false;

        for (
          let indexOfChildActions = 0;
          indexOfChildActions <
          state.currentBot.actions[indexOfActions].childActions.length;
          ++indexOfChildActions
        ) {
          state.currentBot.actions[indexOfActions].childActions[
            indexOfChildActions
          ].isSelected = false;
        }
      }

      const childAction = parentBotAction.childActions.find(
        (childAction) => childAction.botActionId === childActionId
      );

      if (!botAction) {
        const newAction = {
          botActionId: childAction.botActionId,
          isLastBlade: isLastBlade,
          botActionName: childAction.botActionName,
          botActionIntent: childAction.botActionIntent,
          role,
          isVisibleOnScreen: true, // to show the blade
          showActionPanel: true,
          childActions: [],
          childItems: [],
          actionPanelHeader: {
            editOn: true,
            panelHeader: [
              {
                locale: "en-US",
                text: "",
              },
            ],
          },
        };
        state.currentBot.actions.push(newAction);
      } else {
        // we do not create new object as it already exists in the main array and we want to show the blade
        botAction.isVisibleOnScreen = true;
      }

      if (!isInitialize) {
        const previouslySelectedBotChildAction =
          parentBotAction.childActions.find(
            (childAction) => childAction.isSelected === true
          );

        if (previouslySelectedBotChildAction) {
          previouslySelectedBotChildAction.isSelected = false;

          // to remove the blade of the previously selected and visible action
          const previouslySelectedBotAction = state.currentBot.actions.find(
            (botAction) =>
              botAction.botActionId ===
              previouslySelectedBotChildAction.botActionId
          );

          previouslySelectedBotAction.isVisibleOnScreen = false;
        }

        const currentActionByChildActionId = state.currentBot.actions.find(
          (botAction) => botAction.botActionId === childActionId
        );
        if (currentActionByChildActionId) {
          if (currentActionByChildActionId.childActions.length > 0) {
            let firstSubAction = currentActionByChildActionId.childActions[0];
            firstSubAction.isSelected = true;
            const SubActionInActions = state.currentBot.actions.find(
              (botAction) =>
                botAction.botActionId === firstSubAction.botActionId
            );
            SubActionInActions.isVisibleOnScreen = true;
          }
        }

        childAction.isSelected = true;
        state.changesDetected = true;
      }
    },

    showEditDesignModal: (state, action) => {
      state.showEditDesignModal = true;
    },

    showDeleteDesignModal: (state, action) => {
      state.showDeleteDesignModal = true;
    },

    closeEditDesignModal: (state, action) => {
      state.showEditDesignModal = false;
      state.showEditDesignLoader = false;
      state.editDesignErrorMessage = "";
    },

    closeDeleteDesignModal: (state, action) => {
      state.showDeleteDesignModal = false;
      state.showDeleteDesignLoader = false;
      state.deleteDesignErrorMessage = "";
    },

    showEditUserModal: (state, action) => {
      state.showEditUserModal = true;
    },

    showDeleteUserModal: (state, action) => {
      state.showDeleteUserModal = true;
    },

    showAssignEmployeeModel: (state, action) => {
      state.showAssignEmployeeModel = true;
    },

    showAssignPackagerModel: (state, action) => {
      state.showAssignPackagerModel = true;
    },

    closeEditUserModal: (state, action) => {
      state.showEditUserModal = false;
      state.showEditUserLoader = false;
      state.editUserErrorMessage = "";
    },

    closeDeleteUserModal: (state, action) => {
      state.showDeleteUserModal = false;
      state.showDeleteUserLoader = false;
      state.deleteUserErrorMessage = "";
    },

    closeAssignEmployeeModel: (state, action) => {
      state.showAssignEmployeeModel = false;
      state.showAssignEmployeeLoader = false;
      state.assignEmployeeErrorMessage = "";
    },

    closeAssignPackagerModel: (state, action) => {
      state.showAssignPackagerModel = false;
      state.showAssignPackagerLoader = false;
      state.assignPackagerErrorMessage = "";
    },
  },

  extraReducers: (builder) => {
    builder.addCase(UserLoginThunk.pending, (state, action) => {
      state.showLoginLoader = true;
      state.loginErrorMessage = "";
    });

    builder.addCase(UserLoginThunk.fulfilled, (state, action) => {
      state.showLoginLoader = false;
      state.username = action.meta.arg.username;
      state.loginToken = action.payload.token;
      state.userRole = action.payload.role;
      state.isUserLogin = true;
    });

    builder.addCase(UserLoginThunk.rejected, (state, action) => {
      state.showLoginLoader = false;
      state.isUserLogin = false;
      state.loginErrorMessage = action.error.message;
    });

    //#region createNewDesign
    builder.addCase(createNewDesignThunk.pending, (state, action) => {
      state.showAddDesignLoader = true;
      state.addDesignErrorMessage = "";
    });

    builder.addCase(createNewDesignThunk.fulfilled, (state, action) => {
      state.showAddDesignLoader = false;
    });

    builder.addCase(createNewDesignThunk.rejected, (state, action) => {
      state.showAddDesignLoader = false;
      state.addDesignErrorMessage = action.error.message;
    });
    //#endregion

    //#region editDesign
    builder.addCase(editDesignThunk.pending, (state, action) => {
      state.showEditDesignModal = true;
      state.showEditDesignLoader = true;
      state.editDesignErrorMessage = "";
    });

    builder.addCase(editDesignThunk.fulfilled, (state, action) => {
      state.showEditDesignLoader = false;
      state.showEditDesignModal = false;
      state.reloadDesigns++;
    });

    builder.addCase(editDesignThunk.rejected, (state, action) => {
      state.showEditDesignLoader = false;
      state.editDesignErrorMessage = action.error.message;
    });
    //#endregion

    //#region deleteDesign
    builder.addCase(deleteDesignThunk.pending, (state, action) => {
      state.showDeleteDesignModal = true;
      state.showDeleteDesignLoader = true;
      state.deleteDesignErrorMessage = "";
    });

    builder.addCase(deleteDesignThunk.fulfilled, (state, action) => {
      state.showDeleteDesignLoader = false;
      state.showDeleteDesignModal = false;
      state.reloadDesigns++;
    });

    builder.addCase(deleteDesignThunk.rejected, (state, action) => {
      state.showDeleteDesignLoader = false;
      state.deleteDesignErrorMessage = action.error.message;
    });
    //#endregion

    //#region getAllDesign
    builder.addCase(getAllDesignsThunk.pending, (state, action) => {
      state.showGetAllDesignsLoader = true;
      state.getAllDesignsErrorMessage = "";
    });

    builder.addCase(getAllDesignsThunk.fulfilled, (state, action) => {
      state.designList = action.payload.designCollection;
      state.totalDesignCount = action.payload.totalCount;
      state.showGetAllDesignsLoader = false;
    });

    builder.addCase(getAllDesignsThunk.rejected, (state, action) => {
      state.showGetAllDesignsLoader = true;
      state.getAllDesignsErrorMessage = action.error.message;
    });
    //#endregion

    //#region getAllDesign
    builder.addCase(getAllDesignsNamesThunk.pending, (state, action) => {
      state.showGetAllDesignsLoader = true;
      state.getAllDesignsErrorMessage = "";
    });

    builder.addCase(getAllDesignsNamesThunk.fulfilled, (state, action) => {
      state.designList = action.payload.designCollection;
      state.totalDesignCount = action.payload.totalCount;
      state.showGetAllDesignsLoader = false;
    });

    builder.addCase(getAllDesignsNamesThunk.rejected, (state, action) => {
      state.showGetAllDesignsLoader = true;
      state.getAllDesignsErrorMessage = action.error.message;
    });
    //#endregion

    //#region createNewInventory
    builder.addCase(createNewInventoryThunk.pending, (state, action) => {
      state.showAddInventoryLoader = true;
      state.addInventoryErrorMessage = "";
    });

    builder.addCase(createNewInventoryThunk.fulfilled, (state, action) => {
      state.showAddInventoryLoader = false;
      state.newInventoryArray = [];
      state.notFoundSku = action.payload.notFoundSku;
    });

    builder.addCase(createNewInventoryThunk.rejected, (state, action) => {
      state.showAddInventoryLoader = false;
      state.addInventoryErrorMessage = action.error.message;
    });
    //#endregion

    //#region getAllInventoryThunk
    builder.addCase(getAllInventoryThunk.pending, (state, action) => {
      state.showGetAllInventoryLoader = true;
      state.getAllInventoryErrorMessage = "";
    });

    builder.addCase(getAllInventoryThunk.fulfilled, (state, action) => {
      state.inventoryList = action.payload.newDesignList;
      state.totalInventoryCount = action.payload.totalCount;
      state.showGetAllInventoryLoader = false;
    });

    builder.addCase(getAllInventoryThunk.rejected, (state, action) => {
      state.showGetAllInventoryLoader = false;
      state.getAllInventoryErrorMessage = action.error.message;
    });
    //#endregion

    //#region getInventoryDetailsBySku
    builder.addCase(
      getInventoryDetailsByProductIdThunk.pending,
      (state, action) => {
        state.skuDetails = action.payload;
        state.showSkuDetailsLoader = true;
        state.skuDetailsErrorMessage = "";
      }
    );

    builder.addCase(
      getInventoryDetailsByProductIdThunk.fulfilled,
      (state, action) => {
        state.skuDetails = action.payload;
        state.showSkuDetailsLoader = false;
      }
    );

    builder.addCase(
      getInventoryDetailsByProductIdThunk.rejected,
      (state, action) => {
        state.showSkuDetailsLoader = false;
        state.skuDetailsErrorMessage = "";
      }
    );
    //#endregion

    //#region createNewUser
    builder.addCase(createNewUserThunk.pending, (state, action) => {
      state.showAddUserLoader = true;
      state.addUserErrorMessage = "";
    });

    builder.addCase(createNewUserThunk.fulfilled, (state, action) => {
      state.showAddUserLoader = false;
    });

    builder.addCase(createNewUserThunk.rejected, (state, action) => {
      state.showAddUserLoader = false;
      state.addUserErrorMessage = action.error.message;
    });
    //#endregion

    //#region editUser
    builder.addCase(editUserThunk.pending, (state, action) => {
      state.showEditUserModal = true;
      state.showEditUserLoader = true;
      state.editUserErrorMessage = "";
    });

    builder.addCase(editUserThunk.fulfilled, (state, action) => {
      state.showEditUserLoader = false;
      state.showEditUserModal = false;
      state.reloadUsers++;
    });

    builder.addCase(editUserThunk.rejected, (state, action) => {
      state.showEditUserLoader = false;
      state.editUserErrorMessage = action.error.message;
    });
    //#endregion

    //#region deleteUser
    builder.addCase(deleteUserThunk.pending, (state, action) => {
      state.showDeleteUserModal = true;
      state.showDeleteUserLoader = true;
      state.deleteUserErrorMessage = "";
    });

    builder.addCase(deleteUserThunk.fulfilled, (state, action) => {
      state.showDeleteUserLoader = false;
      state.showDeleteUserModal = false;
      state.reloadUsers++;
    });

    builder.addCase(deleteUserThunk.rejected, (state, action) => {
      state.showDeleteUserLoader = false;
      state.deleteUserErrorMessage = action.error.message;
    });
    //#endregion

    //#region assignEmployee
    builder.addCase(assignedEmployeeThunk.pending, (state, action) => {
      state.showAssignEmployeeModel = true;
      state.showAssignEmployeeLoader = true;
      state.assignEmployeeErrorMessage = "";
    });

    builder.addCase(assignedEmployeeThunk.fulfilled, (state, action) => {
      state.showAssignEmployeeLoader = false;
      state.showAssignEmployeeModel = false;
      state.reloadUsers++;
    });

    builder.addCase(assignedEmployeeThunk.rejected, (state, action) => {
      state.showAssignEmployeeLoader = false;
      state.assignEmployeeErrorMessage = action.error.message;
    });
    //#region

    //#region assignpackager
    builder.addCase(assignPackagerThunk.pending, (state, action) => {
      state.showAssignPackagerModel = true;
      state.showAssignPackagerLoader = true;
      state.assignPackagerErrorMessage = "";
    });

    builder.addCase(assignPackagerThunk.fulfilled, (state, action) => {
      state.showAssignPackagerLoader = false;
      state.showAssignPackagerModel = false;
      state.reloadUsers++;
    });

    builder.addCase(assignPackagerThunk.rejected, (state, action) => {
      state.showAssignPackagerLoader = false;
      state.assignPackagerErrorMessage = action.error.message;
    });
    //#region

    //#region getAllUser
    builder.addCase(getAllUsersThunk.pending, (state, action) => {
      state.showGetAllUsersLoader = true;
      state.getAllUsersErrorMessage = "";
    });

    builder.addCase(getAllUsersThunk.fulfilled, (state, action) => {
      state.userList = action.payload.userCollection;
      state.totalUserCount = action.payload.totalCount;
      state.showGetAllUsersLoader = false;
    });

    builder.addCase(getAllUsersThunk.rejected, (state, action) => {
      state.showGetAllUsersLoader = false;
      state.getAllUsersErrorMessage = action.error.message;
    });
    //#endregion

    //#region createNewOrder
    builder.addCase(createNewOrderThunk.pending, (state, action) => {
      state.showAddOrderLoader = true;
      state.addOrderErrorMessage = "";
    });

    builder.addCase(createNewOrderThunk.fulfilled, (state, action) => {
      state.showAddOrderLoader = false;
    });

    builder.addCase(createNewOrderThunk.rejected, (state, action) => {
      state.showAddOrderLoader = false;
      state.addOrderErrorMessage = action.error.message;
    });
    //#endregion

    //#region getAllPendingOrders
    builder.addCase(getAllPendingOrdersThunk.pending, (state, action) => {
      state.showGetAllPendingOrdersLoader = true;
      state.getAllPendingOrdersErrorMessage = "";
    });

    builder.addCase(getAllPendingOrdersThunk.fulfilled, (state, action) => {
      state.orderList = action.payload.orderList;
      state.totalOrderCount = action.payload.totalCount;
      state.showGetAllPendingOrdersLoader = false;
    });

    builder.addCase(getAllPendingOrdersThunk.rejected, (state, action) => {
      state.showGetAllPendingOrdersLoader = false;
      state.getAllPendingOrdersByEmployeeErrorMessage = action.error.message;
    });
    //#endregion
    //#region getAllPendingOrdersByEmployee/////////////////////////////////////////////////////////////////
    builder.addCase(
      getPendingOrdersByEmployeeThunk.pending,
      (state, action) => {
        state.showGetAllPendingOrdersByEmployeeLoader = true;
        state.getAllPendingOrdersByEmployeeErrorMessage = "";
      }
    );

    builder.addCase(
      getPendingOrdersByEmployeeThunk.fulfilled,
      (state, action) => {
        state.orderList1 = action.payload.orderList1;
        state.totalOrderCount1 = action.payload.totalOrderCount1;
        state.showGetAllPendingOrdersByEmployeeLoader = false;
      }
    );

    builder.addCase(
      getPendingOrdersByEmployeeThunk.rejected,
      (state, action) => {
        state.showGetAllPendingOrdersByEmployeeLoader = false;
        state.getAllPendingOrdersByEmployeeErrorMessage = action.error.message;
      }
    );
    //#endregion //////////////////////////////////////////////////////////////////////////////////////////

    //#region getAllCompletedOrders
    builder.addCase(getAllCompletedOrdersThunk.pending, (state, action) => {
      state.showGetAllCompletedOrdersLoader = true;
      state.getAllCompletedOrdersErrorMessage = "";
    });

    builder.addCase(getAllCompletedOrdersThunk.fulfilled, (state, action) => {
      state.orderList = action.payload.orderList;
      state.totalOrderCount = action.payload.totalCount;
      state.showGetAllCompletedOrdersLoader = false;
    });

    builder.addCase(getAllCompletedOrdersThunk.rejected, (state, action) => {
      state.showGetAllCompletedOrdersLoader = false;
      state.getAllCompletedOrdersErrorMessage = action.error.message;
    });
    //#region getAllReturnOrders

    builder.addCase(getAllReturnOrdersThunk.pending, (state, action) => {
      state.showGetAllReturnOrdersLoader = true;
      state.getAllReturnOrdersErrorMessage = "";
    });

    builder.addCase(getAllReturnOrdersThunk.fulfilled, (state, action) => {
      state.returnOrders = action.payload.returnOrders;
      state.returnOrdersTotalCount = action.payload.returnOrdersTotalCount;
      state.showGetAllReturnOrdersLoader = false;
    });

    builder.addCase(getAllReturnOrdersThunk.rejected, (state, action) => {
      state.showGetAllReturnOrdersLoader = false;
      state.getAllReturnOrdersErrorMessage = action.error.message;
    });
    //#endregion

    //#region getAllRemittanceOrder
    builder.addCase(getAllRemittanceCODOrders.pending, (state, action) => {
      state.showGetAllRemittanceOrdersLoader = true;
      state.getAllRemittanceOrdersErrorMessage = "";
    });

    builder.addCase(getAllRemittanceCODOrders.fulfilled, (state, action) => {
      state.orderList = action.payload.data.data;
      state.totalOrderCount = action.payload.data.totalCount;
      state.showGetAllRemittanceOrdersLoader = false;
    });

    builder.addCase(getAllRemittanceCODOrders.rejected, (state, action) => {
      state.showGetAllRemittanceOrdersLoader = false;
      state.getAllRemittanceOrdersErrorMessage = action.error.message;
    });

    // Fetch   RTO Region
    builder.addCase(fetchRTOThunk.pending, (state, action) => {
      state.fetchingRTO = true;
      state.fetchingRTOErrorMessage = "";
      state.fetchRTOStatus = {
        show: false,
        message: "",
        status: "",
      };
    });

    builder.addCase(fetchRTOThunk.fulfilled, (state, action) => {
      state.fetchRTOStatus = {
        show: true,
        message: state.fetchRTOResult || "Success!",
        status: "success",
      };
      state.fetchRTOResult = action.payload.message;
      state.fetchingRTO = false;
    });

    builder.addCase(fetchRTOThunk.rejected, (state, action) => {
      state.fetchRTOStatus = {
        show: true,
        message: state.fetchingRTOErrorMessage || "An error occurred!",
        status: "error",
      };
      state.fetchingRTO = false;

      state.fetchingRTOErrorMessage = action.error.message;
    });
    builder.addCase("CLEAR_ALERT", (state) => {
      state.fetchRTOStatus = {
        show: false,
        message: "",
        status: "",
      }; // Reset alert state
    });
    // End Fetch RTO Region

    //#region getOrderIdListWithItems
    builder.addCase(getOrderIdListWithItemsThunk.pending, (state, action) => {
      state.showGetAllOrderIdListWithItemsLoader = true;
      state.getAllOrderIdwithItemsErrorMessage = "";
    });

    builder.addCase(getOrderIdListWithItemsThunk.fulfilled, (state, action) => {
      state.orderIdListWithItems = action.payload;
      state.showGetAllOrderIdListWithItemsLoader = false;
    });

    builder.addCase(getOrderIdListWithItemsThunk.rejected, (state, action) => {
      state.showGetAllOrderIdListWithItemsLoader = false;
      state.getAllOrderIdwithItemsErrorMessage = action.error.message;
    });
    //#endregion

    //#region createNewRack
    builder.addCase(addNewRackThunk.pending, (state, action) => {
      state.showAddRackLoader = true;
      state.errorAddRackMessage = "";
    });

    builder.addCase(addNewRackThunk.fulfilled, (state, action) => {
      state.showAddRackLoader = false;
      state.errorAddRackMessage = "";
    });

    builder.addCase(addNewRackThunk.rejected, (state, action) => {
      state.showAddRackLoader = false;
      state.errorAddRackMessage = action.error.message;
    });
    //#endregion

    //#region getAllRacksByFloor
    builder.addCase(getAllRacksByFloorThunk.pending, (state, action) => {
      state.showRackListByFloorLoader = true;
      state.rackListByFloorErrorMessage = "";
    });

    builder.addCase(getAllRacksByFloorThunk.fulfilled, (state, action) => {
      state.rackListByFloor = action.payload;
      state.showRackListByFloorLoader = false;
    });

    builder.addCase(getAllRacksByFloorThunk.rejected, (state, action) => {
      state.showRackListByFloorLoader = false;
      state.rackListByFloorErrorMessage = action.error.message;
    });
    //#endregion

    //#region getAllRacks
    builder.addCase(getAllRacksThunk.pending, (state, action) => {
      state.showRackListLoader = true;
      state.getRackListErrorMessage = "";
    });

    builder.addCase(getAllRacksThunk.fulfilled, (state, action) => {
      state.rackList = action.payload;
      state.showRackListLoader = false;
    });

    builder.addCase(getAllRacksThunk.rejected, (state, action) => {
      state.showRackListByFloorLoader = false;
      state.getRackListErrorMessage = action.error.message;
    });
    //#endregion

    //#region addRackToDesign
    builder.addCase(addRacksToDesignThunk.pending, (state, action) => {
      state.showAddRackToDesignLoader = true;
    });

    builder.addCase(addRacksToDesignThunk.fulfilled, (state, action) => {
      state.showAddRackToDesignLoader = false;
      state.addRackToDesignErrorMessage = "";
      state.reloadDesigns++;
    });

    builder.addCase(addRacksToDesignThunk.rejected, (state, action) => {
      state.showAddRackToDesignLoader = false;
      state.addRackToDesignErrorMessage = action.error.message;
    });
    //#endregion

    //#region getDesignsByFloorAndRackThunk
    builder.addCase(getDesignsByFloorAndRackThunk.pending, (state, action) => {
      state.showGetDesignsByFloorAndRackLoader = true;
      state.getDesignsByFloorAndRackErrorMessage = "";
    });

    builder.addCase(
      getDesignsByFloorAndRackThunk.fulfilled,
      (state, action) => {
        state.designListByFloorAndRack = action.payload;
        state.showGetDesignsByFloorAndRackLoader = false;
      }
    );

    builder.addCase(getDesignsByFloorAndRackThunk.rejected, (state, action) => {
      state.showGetDesignsByFloorAndRackLoader = false;
      state.getDesignsByFloorAndRackErrorMessage = action.error.message;
    });
    //#endregion

    //#region markOrderAsCompletedThunk
    builder.addCase(markOrderAsCompletedThunk.pending, (state, action) => {
      state.showMarkOrderAsCompletedLoader = true;
      state.markOrderAsCompletedErrorMessage = "";
    });

    builder.addCase(markOrderAsCompletedThunk.fulfilled, (state, action) => {
      state.showMarkOrderAsCompletedLoader = false;
    });

    builder.addCase(markOrderAsCompletedThunk.rejected, (state, action) => {
      state.showMarkOrderAsCompletedLoader = false;
      state.markOrderAsCompletedErrorMessage = action.error.message;
    });
    //#endregion

    //#region getPendingOrdersForPackaging
    builder.addCase(
      getPendingOrdersForPackagingThunk.pending,
      (state, action) => {
        state.showGetPendingOrdersForPackagingLoader = true;
        state.getPendingOrdersForPackagingErrorMessage = "";
      }
    );

    builder.addCase(
      getPendingOrdersForPackagingThunk.fulfilled,
      (state, action) => {
        state.showGetPendingOrdersForPackagingLoader = false;
        state.pendingOrderItemsList = action.payload.data;
        state.pendingOrderTotal = action.payload.total;
      }
    );

    builder.addCase(
      getPendingOrdersForPackagingThunk.rejected,
      (state, action) => {
        state.showGetPendingOrdersForPackagingLoader = false;
        state.getPendingOrdersForPackagingErrorMessage = action.error.message;
      }
    );
    //#endregion

    //#region getOrdersBySku
    builder.addCase(getGetOrdersBySkuThunk.pending, (state, action) => {
      state.showGetOrdersBySkuLoader = true;
      state.getOrdersBySkuErrorMessage = "";
    });

    builder.addCase(getGetOrdersBySkuThunk.fulfilled, (state, action) => {
      state.showGetOrdersBySkuLoader = false;
      state.orderListBySku = action.payload.data;
    });

    builder.addCase(getGetOrdersBySkuThunk.rejected, (state, action) => {
      state.showGetOrdersBySkuLoader = false;
      state.getOrdersBySkuErrorMessage = action.error.message;
    });
    //#endregion

    //#region generateInvoice
    builder.addCase(generateLabelThunk.pending, (state, action) => {
      // state.showGetOrdersBySkuLoader = true;
      // state.getOrdersBySkuErrorMessage = "";
    });

    builder.addCase(generateLabelThunk.fulfilled, (state, action) => {
      // state.showGetOrdersBySkuLoader = false;
      state.generatedLabel = action.payload.data;
      state.isLabelGenerated = action.payload.labelGenerated;
      // state.labelURL.url = action.payload.data;
      // state.labelURL.hasURL = true;
      // Open the URL in a new tab
      window.open(action.payload.data, "_blank");
      console.log(state.generatedLabel);
    });

    builder.addCase(generateLabelThunk.rejected, (state, action) => {
      // state.showGetOrdersBySkuLoader = false;
      // state.getOrdersBySkuErrorMessage = action.error.message;
    });
    //#endregion
    //#region getOrdersBySku
    builder.addCase(getOrderDeliverdThunk.pending, (state, action) => {
      state.showGetOrdersDeliverdLoader = true;
      state.getOrdersDeliverdErrorMessage = "";
    });

    builder.addCase(getOrderDeliverdThunk.fulfilled, (state, action) => {
      state.showGetOrdersDeliverdLoader = false;
      state.showGetOrdersDeliverdLoader = false;
      state.OrderDeliverd = action.payload.orderList;
    });

    builder.addCase(getOrderDeliverdThunk.rejected, (state, action) => {
      state.showGetOrdersDeliverdLoader = false;
      state.showGetOrdersDeliverdLoader = false;
      state.getOrdersDeliverdErrorMessage = action.error.message;
    });
    //#endregion
    //#region get all custom orders
    builder.addCase(getAllCustomeOrdersThunk.pending, (state, action) => {
      state.showGetAllCustomLoader = true;
      state.getCustomeOrderErrorMessage = "";
    });

    builder.addCase(getAllCustomeOrdersThunk.fulfilled, (state, action) => {
      state.showGetAllCustomLoader = false;
      state.getcustomeOrdermessage = action.payload.message;
    });

    builder.addCase(getAllCustomeOrdersThunk.rejected, (state, action) => {
      state.showGetAllCustomLoader = false;
      state.getCustomeOrderErrorMessage = action.error.message;
    });
    //#endregion

    //#region getAllScannedOrders
    builder.addCase(getAllScannedOrdersThunk.pending, (state, action) => {
      state.showScannedOrderLoader = true;
      state.getAllScannedOrdersErrorMessage = "";
    });

    builder.addCase(getAllScannedOrdersThunk.fulfilled, (state, action) => {
      state.scannedOrderList = action.payload.scannedOrderList;
      state.scannedOrderTotalCount = action.payload.scannedOrderTotalCount;
      state.showScannedOrderLoader = false;
    });

    builder.addCase(getAllScannedOrdersThunk.rejected, (state, action) => {
      state.showScannedOrderLoader = false;
      state.getAllScannedOrdersErrorMessage = action.error.message;
    });
  },
});

export const {
  onInitialize,
  addNewAction,
  addNewBotActionToBladeActions,
  onSelectedBot,
  botActionClose,
  showEditDesignModal,
  closeEditDesignModal,
  showDeleteDesignModal,
  closeDeleteDesignModal,
  closeDeleteUserModal,
  closeEditUserModal,
  showDeleteUserModal,
  showEditUserModal,
  showAssignEmployeeModel,
  closeAssignEmployeeModel,
  showAssignPackagerModel,
  closeAssignPackagerModel,
  showAssignPackagerLoader,
  showAssignEmployeeLoader,
  updateInventoryArray,
  getOrdersDeliverdErrorMessage,
  getCustomeOrderErrorMessage,
  showGetOrdersDeliverdLoader,
  showGetAllCustomLoader,
  getcustomeOrdermessage,
  getAllPendingOrdersByEmployeeErrorMessage,
  showGetAllPendingOrdersByEmployeeLoader,
} = chatBotCreatorSlice.actions;

export default chatBotCreatorSlice;
