import SearchIcon from "@mui/icons-material/Search";
import { Button, Card, CardContent, Pagination } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAllRemittanceCODOrders } from "../store/chatBotCreatorThunk";

import DatePicker from "react-datepicker";
import axios from "axios";
const { REACT_APP_SERVER_URL } = process.env;

function OrderCard({ order }) {
  return (
    <Card className="card-border mb-3" fullWidth>
      <CardContent sx={{ padding: ".8rem !important" }}>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div className="row">
              <div className="col-2">
                <div className="small-font">Order Id</div>
                <b className="text-primary">{order.orderId}</b>
                <br />
                <label className="small-font">
                  {moment(order.updatedDateTime).format("DD MMM, YYYY")}
                </label>
                <br />
                <label className="small-font">
                  {moment(order.updatedDateTime).format("hh:mm a")}
                </label>
              </div>

              <div className="col-3">
                <div className="small-font">Customer:</div>
                <b>{order.customerName}</b>
                <div className="small-font">
                  {order.customerAddress?.address1},{" "}
                  {order.customerAddress?.address2},{" "}
                  {order.customerAddress?.city}, {order.customerAddress?.state},
                  {order.customerAddress?.country},
                  {order.customerAddress?.zipcode},
                  <div>
                    phone : <b>{order?.customerPhone} </b>
                  </div>
                </div>
              </div>

              <div className="col-2">
                <b className="text-success">
                  {order?.channelName ? order?.channelName : "SHOPIFY"}
                </b>
                &nbsp;
                <br />
                <label className="small-font">
                  <b>awb:</b> {order?.awb ? order?.awb : "not assigned"}
                </label>
                <br />
                <label className="small-font">
                  {order?.courierName ? order?.courierName : ""}
                </label>
              </div>

              <div className="col-2">
                <label className="small-font">
                  <b
                    style={{
                      color: order.paymentDetails?.paymentReceived
                        ? "green"
                        : "red",
                      fontSize: "16px",
                    }}
                  >
                    {order.paymentDetails?.paymentReceived
                      ? "Payment Received"
                      : "Payment Not Received"}
                  </b>
                </label>
              </div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

function PaymentComponent() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.chatBotCreatorStore);
  const date = new Date();

  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState(
    new Date(date.setMonth(date.getMonth() - 1))
  );
  const [endDate, setEndDate] = useState(new Date());
  const [status, setStatus] = useState("PAYMENT NOT RECEIVED");

  const formatDate = (date) => {
    return date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    });
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const onClickCustomSearch = () => {
    const mappedSearch = status === "PAYMENT RECEIVED" ? true : false;
    dispatch(
      getAllRemittanceCODOrders({
        JWT: state.loginToken,
        startDate: formatDate(startDate),
        endDate: formatDate(endDate),
        pageSize: 10,
        currentPage,
        status: mappedSearch,
      })
    );
  };

  const downloadCSV = async () => {
    try {
      const mappedSearch = status === "PAYMENT RECEIVED" ? true : false;

      const queryParams = new URLSearchParams({
        from: formatDate(startDate),
        to: formatDate(endDate),
        status: mappedSearch.toString(),
      }).toString();

      const response = await axios.get(
        `${REACT_APP_SERVER_URL}/order/v1/download-all-payment-cod-data?${queryParams}`,
        {
          headers: {
            Authorization: `Bearer ${state.loginToken}`,
          },
        }
      );

      if (response.status === 200) {
        const csvBlob = new Blob([response.data.data], {
          type: "text/csv",
        });
        const csvUrl = URL.createObjectURL(csvBlob);

        const link = document.createElement("a");
        link.href = csvUrl;
        link.setAttribute("download", `payment_orders.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error("Error downloading CSV: ", error);
    }
  };

  useEffect(() => {
    onClickCustomSearch();
  }, [currentPage, startDate, endDate, status]);
  return (
    <>
      <div className="row mb-2 ">
        <div className="col-3 d-flex">
          <b className="h6"> Start Date :</b>
          <div className="w-min">
            <DatePicker
              selectsStart
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              startDate={startDate}
              placeholderText="Select start date"
            />
          </div>
        </div>
        <div className="col-3 d-flex ">
          <b className="h6"> End Date :</b>
          <div>
            <DatePicker
              selectsEnd
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              endDate={endDate}
              startDate={startDate}
              minDate={startDate}
              placeholderText="Select end date"
            />
          </div>
        </div>
        <div className="col-3 d-flex ">
          <b className="h6">Payment Status:</b>
          <select
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            style={{ height: "30px" }}
          >
            <option value={"PAYMENT RECEIVED"}>PAYMENT RECEIVED</option>
            <option value={"PAYMENT NOT RECEIVED"}>PAYMENT NOT RECEIVED</option>
          </select>
        </div>
        <div className="col-2 ">
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<SearchIcon />}
            onClick={downloadCSV}
          >
            Download CSV
          </Button>
        </div>
      </div>

      {/* Order List */}
      {state.getAllRemittanceOrdersErrorMessage === "" ? (
        <div className="col-12" style={{ height: "94%", overflowY: "scroll" }}>
          {state.showGetAllCompletedOrdersLoader ? (
            <div className="centered" style={{ height: "300px" }}>
              <div className="spinner-border mx-2"></div>
              <span>Loading Order List...</span>
            </div>
          ) : state.orderList?.length === 0 ? (
            <h6>No Payment Orders Found.</h6>
          ) : (
            state.orderList?.map((order, index) => (
              <OrderCard key={index} order={order} />
            ))
          )}

          {/* Pagination */}
          {state.orderList?.length > 0 && (
            <div
              className="mt-3 d-flex justify-content-center"
              style={{ position: "fixed", right: 10, bottom: 2 }}
            >
              <Pagination
                count={
                  Math.ceil(state.totalOrderCount / 10) > 10
                    ? Math.ceil(state?.totalOrderCount / 10)
                    : Math.ceil(state.totalOrderCount / 10)
                }
                color="primary"
                page={currentPage}
                onChange={handlePageChange}
              />
            </div>
          )}
        </div>
      ) : (
        <p className="text-danger centered">
          {state.getAllRemittanceOrdersErrorMessage}
        </p>
      )}
    </>
  );
}

export default PaymentComponent;
